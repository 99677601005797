import React from "react";
import './Card.css';

const Card = ({title, description, link, date, titleColor}) => {
    return (
        <div className="card">
            <h2 style={{color: `${titleColor}`}}>{title}</h2>
            <p>{description}</p>
            <p >Origin Date: {date}</p>
            <div className="footer">
                <a href={link}>View Details ↗</a>
            </div>
        </div>

    );
  }

export default Card;