import React from 'react';
import './Download.css'; // Import CSS file with animations

const isMobile = () => { // Function for mobile device detection (optional)
  const userAgent = navigator.userAgent;
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
};

const Download = () => {
  const downloadLink = isMobile() ? '/resume.pdf' : '/resume.pdf'; // Adjust paths if needed

  return (
    <div className="download-container">
      <a
        href={downloadLink}// Path to your PDF file in the public folder
        className="download-button"
        download={isMobile() ? 'resume.pdf' : undefined} // Set download attribute for mobiles
        rel="noopener noreferrer" // Security for older browsers
      >
        {isMobile() ? 'Download ↗' : 'View ↗'}
      </a>
    </div>
  );
};

export default Download;