import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import Links from './pages/links/links';
import Blog from './pages/blog/blog';
import NotFound from './pages/notfound/NotFound';
import Progressbar from "./components/progressbar/Progressbar";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

function App() {
  const currentPath = window.location.pathname; // Get the current URL path

  return (
    <Router>
      
    <Progressbar />
    <Header />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/links" element={<Links />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <Footer /> 
    </Router>
  );
}

export default App;
