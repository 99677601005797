import React from "react";
import './NotFound.css';

const NotFound  = () => {
    return (
      <>

        <div className="container-404">
            <h1>404</h1>
            <p>
                The page you are looking for does not exist.
            </p>
        </div>

      </>
    );
  };
  
export default NotFound ;
  