
import './Techs.css'; // Import CSS file with animations

const Techs = () => {
  return (
    <div>
        <p>
        Here are some of the technologies I've worked with. 
        I'm always looking to
        learn new things and improve my skills.
        </p>
        <div className="techs-container">
        <p className="techs">
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=js" alt="JavaScript Logo"></img>
        <span className="icon-name">JavaScript</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=py" alt="Python Logo"></img>
        <span className="icon-name">Python</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=cpp" alt="C++ Logo"></img>
        <span className="icon-name">CPP</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=java" alt="Java Logo"></img>
        <span className="icon-name">Java</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=cs" alt="C# Logo"></img>
        <span className="icon-name">C#</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=rust" alt="Rust Logo"></img>
        <span className="icon-name">Rust</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=go" alt="Go Logo"></img>
        <span className="icon-name">Go</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=react" alt="React Logo"></img>
        <span className="icon-name">React</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=astro" alt="Astro Logo"></img>
        <span className="icon-name">Astro</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=svelte" alt="Svelte Logo"></img>
        <span className="icon-name">Svelte</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=nodejs" alt="Node.js Logo"></img>
        <span className="icon-name">Node.js</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=nextjs" alt="Next.js Logo"></img>
        <span className="icon-name">Next.js</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=mongodb" alt="MongoDB Logo"></img>
        <span className="icon-name">MongoDB</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=git" alt="Git Logo"></img>
        <span className="icon-name">Git</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=mysql" alt="MySql Logo"></img>
        <span className="icon-name">MySQL</span>
        </span>
        <span className="icon-container">
        <img src="https://skillicons.dev/icons?i=postgres" alt="Postgres Logo"></img>
        <span className="icon-name">PostgreSQL</span>
        </span>
        </p>
        </div>
    </div>
  );
};

export default Techs;


