import React from "react";
import './blog.css';

const blog = () => {
    return (
      <>

        <div className="blog-container">
          <p>
            Here are some of the blog posts I have written.
          </p>
          <ul>
            <li>
              <a>Coming soon...</a>
            </li>
          </ul>
        </div>
      </>
    );
  };
  
export default blog;
  