import React from "react";
import Download from "../../../src/components/download/Download";
import Card from "../src/../../components/card/Card"
import Techs from "../src/../../components/techs/Techs";
import './home.css'

const Home = () => {
  return (
    <>

      <div>
        <article>
          <h2>Software Engineering Student 🛠</h2>
          <p>
          Hi! I'm an 18y/o developer with a deep passion for programming
          and a continuous commitment to skill development and personal advancement.
            I love building things that make a difference and I'm always looking for new
            challenges to take.
          </p>
          <p>
            Currently focusing on: <br></br>
            <span className='colorGreen'>#MachineLearning</span>, <span className='colorRed'>#Backend</span>,<br></br><span className='colorSky'>#Frontend</span> development and <span className='colorOrange'>#QuantumComputing</span> as a hobbie!

          </p>
          <p className="location">
            Made in Costa Rica 📍
          </p>

          <h2>Resume 💼</h2>
          <Download />

          <h2>Technologies 🧠</h2>
          <Techs />

          <h2>Projects 🚀</h2>

          <p>
            Here are some of the projects I've worked on. You can find more on my
            github profile.
          </p>
          <Card 
          title="CCAL Website" 
          description="This is a landing page for the CCAL, designed and developed by the @Sagebox team, 
          used React, JavaScript, CSS, MongoDB, Git and more technologies." 
          link="https://www.ccalajuela.ed.cr/"
          titleColor="#ffffff"
          date="10/02/2024"
          />
          <Card 
          title="Image to ASCII Art" 
          description="Used Svelte and JavaScript. It takes an image and converts it to a Canvas Element, then 
          retrieves pixel data from the canvas and converts it to ASCII characters based on pixel 
          intensity. Characters are calculated based on grayscale intensity and mapped to a set of 
          characters representing shades of gray. Still in development..." 
          link="https://github.com/danisotosol/ascii-image-svelte"
          date="01/03/2024"
          titleColor="#ffffff"
          />
          <Card 
          title="mineapi - Discord Bot" 
          description="Used JavaScript and Axios with the Discord.js library to design, develop, and test a discord bot with custom functionalities and commands using the Minecraft.net public API." 
          link="https://github.com/danisotosol/Minecraft-API-Discord.js"
          titleColor="#ffffff"
          date="08/06/2020"
          />
          <center><h4>More projects on my <a href="https://github.com/danisotosol">github</a></h4></center>

        </article>
      </div> 
      
    </>
  );
};

export default Home;
