import React from "react";
import './Header.css'; // Import CSS file with animations

const Header = () => {
  return (
    <header>
      <div className="container">
        <h1><a href='/'>Daniel Soto.</a></h1>
        <nav>
          <ul>
            <li><a href="/blog">blog 📢</a></li>
            <li><a href="/links">links 📌</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
