import React from "react";
import './Footer.css';

const Footer = () => {
    return (
      <>
      <p className='copyright'>
      Copyrigtht © 2024 Daniel Soto. All rights reserved.
      </p>
      </>
    );
  }

export default Footer;